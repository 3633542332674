import React, { useState, useEffect, memo, useRef, useContext } from 'react';
import { graphql } from 'gatsby';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import {
	Container,
	AppBar,
	Tabs,
	Tab,
	Typography,
	Grid,
	Box,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { DetailsHero } from '../components/Details/DetailsHero';
import { DetailsOverviewSection } from '../components/Details/DetailsOverviewSection';
import { DetailsFeatureSection } from '../components/Details/DetailsFeatureSection';
import { DetailsSupportedProductsSection } from '../components/Details/DetailsSupportedProductSection';
import { DetailsRelatedFeaturesSection } from '../components/Details/DetailsRelatedFeaturesSection';
import { DetailsContactForm } from '../components/Details/ContactForm/DetailsContactForm';
import { DetailsFormModal } from '../components/Details/ContactForm/DetailsFormModal';
import { TestimonialLogos } from '../components/Details/TestimonialLogos';
import { Testimonial } from '../components/Details/Testimonial';
import { TestimonialCarousel } from '../components/Details/TestimonialCarousel';
import TabPanel from '../components/Details/TabPanel';
import { FAQ } from '../components/Details/FAQ';
import { SEO } from '../components/SEO';
import { TargetSpecialSection } from '../components/Details/TargetSpecialSection';
import { DetailsExternalContactForm } from '../components/Details/ContactForm/DetailsExternalContactForm';
import TokenContext from '../context/TokenContext';
import { PardotContactForm } from '../components/Details/ContactForm/PardotContactForm';
import { PardotFormModal } from '../components/Details/ContactForm/PardotFormModal';
import { ExternalFormLink } from '../components/Details/ExternalFormLink';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	background: {
		background: theme.white,
	},
	root: {
		minWidth: '100%',
		background: theme.white,
		color: theme.workwaveBlue,
		border: `3px solid ${theme.lightGray}`,
	},
	tabRoot: {
		flexGrow: 1,
	},
	backgroundGradient: {
		paddingBottom: '3rem',
		backgroundImage:
			'linear-gradient(to bottom, #ffffff, #fefeff, #fcfdff, #fafcff, #f8fbff)',
	},
	appBar: {
		padding: '0px',
		background: theme.white,
		color: theme.workwaveBlue,
		boxShadow: 'none',
	},
	tabContainer: {
		borderBottom: `6px solid rgba(0,0,0,0.1)`,
		justifyContent: 'space-between',
	},

	tabText: {
		fontSize: 24,
		textDecoration: 'none',
		fontWeight: 'bold',
		textAlign: 'left',
		whiteSpace: 'nowrap',
		maxWidth: '300px',
		minWidth: 'auto',
		padding: '25px 0',
	},
	[theme.breakpoints.down('sm')]: {
		tabText: {
			marginRight: '30px',
			fontSize: '1.15rem',
			'& > div:nth-child(last)': {
				marginRight: '0px',
			},
		},
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		color: theme.workwaveBlue,
		textAlign: 'center',
	},
	pos: {
		marginBottom: 12,
	},
	faqTitle: {
		color: theme.workwaveBlue,
		textAlign: 'center',
		fontWeight: 'bold',
		marginBottom: '4rem',
		marginTop: '4rem',
	},
	testimonialTitle: {
		color: theme.workwaveBlue,
		textAlign: 'center',
		fontWeight: 'bold',
		marginBottom: '2rem',
		marginTop: '4rem',
	},
	testimonialLogo: {
		marginBottom: '2rem',
	},
	promoBox: {
		background: 'linear-gradient(to top, #002d5c , #4f88c7)',
		borderRadius: '8px',
		color: theme.white,
		padding: '1.5rem',
		margin: '2rem auto',
		maxWidth: '850px',
		display: 'block',
		textAlign: 'center',
		boxShadow:
			'0 13px 27px -5px rgba(50, 50, 93, 0.2), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
	},
	promoBoxText: {
		fontWeight: '600 !important',
		fontSize: '2rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem',
		},
	},
}));

export default function Partner({ data: { addonStaticData, partner } }) {
	const classes = useStyles();
	const formRef = useRef();
	const { user } = useContext(TokenContext);

	const [value, setValue] = useState(0);
	const [formModalOpen, setFormModalOpen] = useState(false);

	//tracks the selected index of the clicked testimonial logo to determine which one to render
	const [selected, setSelected] = useState(0);

	//takes the array for screenshots and the array for videos that go into the carousel and makes them into a single array
	const [carouselArray, setCarouselArray] = useState([]);
	useEffect(() => {
		const screenshots = partner.overview.image;
		!carouselArray.length &&
			setCarouselArray([...screenshots, ...partner.overview.videoVariant]);
	}, [partner]);

	//creates an array of testimonials
	const [carouselTestimonialArray, setCarouselTestimonialArray] = useState([]);
	useEffect(() => {
		!carouselTestimonialArray.length &&
			setCarouselTestimonialArray(partner.testimonials);
	});

	//every tab change triggers a rerender of the whole component. memoized this component to avoid rendering  multiple marketo forms on same page
	const MemoizedForm = memo(DetailsContactForm);
	const MemoizedModal = memo(DetailsFormModal);

	//handler for opening the form modal
	const handleHeroClick = () => {
		setFormModalOpen(true);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleCtaClick = () => {
		formRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	//handler for testimonial logo clicks
	const handleTestimonialClick = (event, index) => {
		setSelected(index);
	};

	//callback to prefill marketo forms
	const callback = () => {
		if (user) {
			window.MktoForms2.onFormRender((form) => {
				form.setValues({
					FirstName: user.Name.split(' ')[0],
					LastName: user.Name.split(' ')[1],
					Email: user.Email,
					Primary_Software_Assets__c:
						user.Product === 'PP'
							? 'PestPac'
							: user.Product === 'PPS'
							? 'PestPac Select'
							: user.Product === 'WWS'
							? 'WorkWave Service'
							: null,
				});
			});
		}
	};

	const med = useMediaQuery('(max-width: 960px)');
	return (
		<>
			<SEO title={partner.metaTitle} description={partner.metaDescription} />
			<div className={classes.backgroundGradient}>
				<DetailsHero
					detail={partner}
					image={partner.heroImage?.asset?.gatsbyImageData}
					handleHeroClick={handleHeroClick}
					formLink={partner.externalFormLink}
				/>
				<Container fixed style={{ background: 'transparent' }}>
					{partner.promo && (
						<Box className={classes.promoBox}>
							<PortableText
								content={partner.promo._rawPromotion}
								serializers={{
									normal: ({ children }) => (
										<Typography className={classes.promoBoxText} variant='h5'>
											{children}
										</Typography>
									),
								}}
							/>
						</Box>
					)}
					<div className={classes.tabRoot}>
						<AppBar position='static' className={classes.appBar}>
							<Tabs
								value={value}
								onChange={handleChange}
								indicatorColor='primary'
								variant='scrollable'
								style={{ marginTop: '12px' }}
								scrollButtons='on'
								TabIndicatorProps={{
									style: {
										height: '6px',
									},
								}}
								classes={{ flexContainer: classes.tabContainer }}
								aria-label='simple tabs example'>
								{addonStaticData.detailTabs.map((tab, index) => (
									<Tab
										className={classes.tabText}
										style={{ padding: '15px' }}
										disableRipple
										label={tab.tabTitle}
										{...a11yProps(index)}
									/>
								))}
							</Tabs>
						</AppBar>
						<TabPanel value={value} index={0}>
							<DetailsOverviewSection
								overview={partner.overview}
								disclaimer={partner.targetSpecial?.targetOverviewDisclaimer}
								carouselArray={carouselArray}
							/>
						</TabPanel>
						<TabPanel value={value} index={1}>
							<DetailsFeatureSection features={partner.features} />
						</TabPanel>
						<TabPanel value={value} index={2}>
							<DetailsSupportedProductsSection supported={partner.supported} />
						</TabPanel>
						<TabPanel value={value} index={3}>
							<DetailsRelatedFeaturesSection
								relatedAddons={partner.relatedAddons}
								relatedPartners={partner.relatedPartners}
							/>
						</TabPanel>
					</div>
					{partner.title === 'Supplier Discount Program' && (
						<TargetSpecialSection body={partner._rawTargetSpecial} />
					)}
					{partner._rawFaq && (
						<>
							<Typography variant='h3' className={classes.faqTitle}>
								{addonStaticData.faqTitle}
							</Typography>

							{partner._rawFaq.map((faq, index) => (
								<FAQ faq={faq} key={index} index={index} />
							))}
						</>
					)}
				</Container>
				{/* <DetailsCta
					cta={addonStaticData.detailsCta}
					handleCtaClick={handleCtaClick}
				/> */}
			</div>
			<div style={{ background: 'white' }}>
				<Container fixed>
					{partner.testimonials.length ? (
						<Grid
							container
							direction='row'
							justify='center'
							alignItems='center'>
							<Grid item xs={12} md={8}>
								<Typography variant='h3' className={classes.testimonialTitle}>
									{partner.testimonialTitle}
								</Typography>
							</Grid>
						</Grid>
					) : null}
					{partner.testimonials.length ? (
						med ? (
							<Grid
								container
								direction='row'
								justify='center'
								alignItems='center'>
								<TestimonialCarousel
									carouselTestimonialArray={carouselTestimonialArray}
								/>
							</Grid>
						) : (
							<>
								{partner.testimonials.length > 1 && (
									<Grid
										container
										direction='row'
										justify='center'
										alignItems='center'
										className={classes.testimonialLogo}>
										{partner.testimonials.map((testimonial, index) => (
											<TestimonialLogos
												key={index}
												index={index}
												image={
													testimonial.testimonialLogo?.asset?.gatsbyImageData
												}
												company={testimonial.company}
												selected={selected}
												handleTestimonialClick={handleTestimonialClick}
											/>
										))}
									</Grid>
								)}

								{partner.testimonials.map((testimonial, index) => (
									<Testimonial
										testimonial={testimonial}
										key={testimonial._id}
										index={index}
										selected={selected}
										ref={formRef}
									/>
								))}
							</>
						)
					) : (
						<div ref={formRef} />
					)}
					{partner.pardotUrl ? (
						<PardotContactForm
							url={partner.pardotUrl}
							contactForm={partner.contactForm}
						/>
					) : partner.externalFormLink ? (
						<ExternalFormLink formLink={partner.externalFormLink} />
					) : partner.marketoId ? (
						<MemoizedForm
							baseUrl='//app-sj02.marketo.com'
							munchkinId='343-MGE-042'
							formId={partner.marketoId}
							contactForm={partner.contactForm}
							modal={false}
							type='partner'
							slug={partner.slug.current}
							open={formModalOpen}
							callback={callback}
						/>
					) : (
						<DetailsExternalContactForm
							formValues={partner.externalForm}
							contactForm={partner.contactForm}
						/>
					)}

					<Typography
						variant='subtitle2'
						style={{ color: '#5A7184', marginBottom: '2rem' }}>
						{partner.promo?._rawDisclaimer?.map((content) => (
							<PortableText content={content} />
						))}
					</Typography>
				</Container>
				{formModalOpen &&
					(partner.pardotUrl ? (
						<PardotFormModal
							open={formModalOpen}
							toggleOpen={setFormModalOpen}
							url={partner.pardotUrl}
							contactForm={partner.contactForm}
						/>
					) : (
						<MemoizedModal
							open={formModalOpen}
							toggleOpen={setFormModalOpen}
							baseUrl='//app-sj02.marketo.com'
							munchkinId='343-MGE-042'
							formId={partner.marketoId}
							contactForm={partner.contactForm}
							callback={callback}
							formValues={partner.externalForm}
						/>
					))}
			</div>
		</>
	);
}

export const query = graphql`
	query ($slug: String!) {
		addonStaticData: sanityAddonStatic {
			_id
			title
			detailTabs {
				tabTitle
			}

			faqTitle
			detailsCta {
				header
				bodyText
				ctaButton
			}
		}
		partner: sanityPartners(slug: { current: { eq: $slug } }) {
			metaTitle
			metaDescription
			_id
			title
			filterHeader
			filterLogo {
				asset {
					gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
				}
			}
			filterBody
			tags
			slug {
				current
			}
			heroImage {
				asset {
					gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
				}
			}
			heroTitle
			heroSubTitle
			heroDescription
			heroCTAText
			promo {
				_rawPromotion
				_rawDisclaimer
			}
			_rawFaq
			faq {
				question
				answer {
					_key
					_type
					style
					list
					children {
						_key
						_type
						marks
						text
						__typename
					}
					style
					list
				}
			}
			overview {
				_key
				title
				bodyHeader
				bodyCopy
				image {
					imageSlide {
						asset {
							url
							gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
						}
					}
				}
				videoVariant {
					wistiaUrl
				}
				blurbs {
					_key
					title
					header
					body
					icon
				}
			}
			features {
				_key
				title
				blurbs {
					_key
					title
					header
					body
					icon
				}
			}
			supported {
				_key
				title
				cards {
					_key
					title
					link
					cardTopImage {
						asset {
							url
							gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
						}
					}
					cardBottomImage {
						asset {
							gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
						}
					}
					cardText
				}
			}
			relatedAddons {
				_key
				title
				filterHeader
				filterIcon
				filterBody
				wwProductVariant {
					filterLogo {
						asset {
							gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, height: 50)
						}
					}
				}
				slug {
					current
				}
			}
			relatedPartners {
				_key
				title
				filterHeader
				filterLogo {
					asset {
						gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
					}
				}
				filterBody
				slug {
					current
				}
			}
			_rawTargetSpecial
			targetSpecial {
				targetOverviewDisclaimer
				targetSpecialSection {
					_key
					_type
					style
					children {
						_key
						marks
						text
						_type
					}
				}
			}
			# testimonialTitle
			testimonials {
				_id
				testimonialText
				testimonialLogo {
					_key
					asset {
						gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
					}
				}
				nameAndTitle
				company
				image {
					_key
					asset {
						gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
					}
				}
			}
			contactForm {
				header
				body
				ctaText
				privacyPolicy
			}
			marketoId
			pardotUrl
			externalFormLink {
				title
				cta
				link
			}
			externalForm {
				fieldLabel
				fieldValue
			}
		}
	}
`;
